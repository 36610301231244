// src/components/LearnMorePage.js

import React from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

// Import images (placeholders for now)
import heroImage from '../assets/learnmore-hero.svg';
import feature1Image from '../assets/feature-shared-todo.svg';
import feature2Image from '../assets/feature-group-gift.svg';

const useStyles = makeStyles((theme) => ({
  heroSection: {
    padding: theme.spacing(8, 0),
    textAlign: 'center',
    backgroundColor: '#f5f5f5',
  },
  heroImage: {
    width: '100%',
    maxWidth: 600,
    margin: '0 auto',
  },
  section: {
    padding: theme.spacing(8, 0),
  },
  featureImage: {
    width: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
  ctaSection: {
    padding: theme.spacing(8, 0),
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

function LearnMorePage() {
  const classes = useStyles();

  return (
    <div>
      {/* Hero Section */}
      <Box className={classes.heroSection}>
        <Container maxWidth="md">
          <Typography variant="h3" component="h1" gutterBottom>
            Discover How ListLink Empowers Your Collaboration
          </Typography>
          <Typography variant="h6" color="textSecondary" paragraph>
            Seamlessly create shared to-do lists and anonymous group gift lists. Enhance your productivity and make group gifting effortless.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="/login"
            sx={{ mt: 4 }}
          >
            Get Started
          </Button>
          <Box mt={4}>
            <img src={heroImage} alt="Learn More" className={classes.heroImage} />
          </Box>
        </Container>
      </Box>

      {/* Feature 1: Shared To-Do Lists */}
      <Box className={classes.section}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom>
                Shared To-Do Lists
              </Typography>
              <Typography variant="body1" paragraph>
                Collaborate with friends, family, or colleagues by creating shared to-do lists. Assign tasks, set deadlines, and keep everyone on the same page.
              </Typography>
              <Typography variant="h6" gutterBottom>
                How to Use:
              </Typography>
              <Typography variant="body1" paragraph>
                1. Sign up or log in to your ListLink account.<br />
                2. Navigate to "My Lists" and click on "Create New List".<br />
                3. Add tasks to your list and invite others to join.<br />
                4. Share the list via email or with groups.<br />
                5. Collaborate in real-time as tasks are updated.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src={feature1Image} alt="Shared To-Do Lists" className={classes.featureImage} />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Feature 2: Anonymous Group Gift Lists */}
      <Box className={classes.section} bgcolor="#f9f9f9">
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
              <img src={feature2Image} alt="Group Gift Lists" className={classes.featureImage} />
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
              <Typography variant="h4" gutterBottom>
                Anonymous Group Gift Lists
              </Typography>
              <Typography variant="body1" paragraph>
                Organize group gifts effortlessly while keeping contributions anonymous. Perfect for surprise gifts, office parties, or any special occasion.
              </Typography>
              <Typography variant="h6" gutterBottom>
                How to Use:
              </Typography>
              <Typography variant="body1" paragraph>
                1. Sign up or log in to your ListLink account.<br />
                2. Navigate to "My Lists" and select "Create Group Gift List".<br />
                3. Add gift ideas and set a budget.<br />
                4. Invite participants anonymously.<br />
                5. Members can contribute and mark items as purchased without revealing their identity.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Call-to-Action Section */}
      <Box className={classes.ctaSection}>
        <Container maxWidth="sm">
          <Typography variant="h4" gutterBottom>
            Ready to Enhance Your Collaboration?
          </Typography>
          <Typography variant="h6" gutterBottom>
            Sign up today and start using ListLink to streamline your tasks and group gifting.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component={Link}
            to="/login"
          >
            Get Started Now
          </Button>
        </Container>
      </Box>
    </div>
  );
}

export default LearnMorePage;
