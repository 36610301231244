// src/components/ListView.js

import React, { useEffect, useState } from 'react';
import api from '../api';
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function ListView() {
  const [lists, setLists] = useState([]);
  const [open, setOpen] = useState(false);
  const [newListName, setNewListName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchLists();
  }, []);

  const fetchLists = async () => {
    try {
      const response = await api.get('/lists');
      if (response.status === 204) {
        setLists([]);
      } else {
        setLists(response.data);
      }
    } catch (error) {
      console.error('Error fetching lists:', error);
      setLists([]);
    }
  };
  

  const handleCreate = async () => {
    try {
      const response = await api.post('/lists', { name: newListName });
      const newListId = response.data._id;
    
      setNewListName('');
      setOpen(false);
      fetchLists();
      // Navigate to the new list's page
      navigate(`/lists/${newListId}`);
    } catch (error) {
      console.error('Error creating list:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/lists/${id}`);
      fetchLists();
    } catch (error) {
      console.error('Error deleting list:', error);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        My Lists
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => setOpen(true)}
      >
        Create New List
      </Button>
      <List>
        {Array.isArray(lists) && lists.length > 0 ? (
          lists.map((list) => (
            <ListItem
              key={list._id}
              secondaryAction={
                <>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => navigate(`/lists/${list._id}`)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(list._id)}
                  >
                    <Delete />
                  </IconButton>
                </>
              }
            >
              <ListItemText
                primary={list.name}
                onClick={() => navigate(`/lists/${list._id}`)}
              />
            </ListItem>
          ))
        ) : (
        <Typography sx={{ mt: 2 }}>No lists found.</Typography>
      )}
      </List>

      {/* Dialog for creating a new list */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create New List</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="List Name"
            type="text"
            fullWidth
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleCreate}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ListView;
