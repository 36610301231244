// src/components/ListDetail.js

import React, { useEffect, useState } from 'react';
import api from '../api';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Button,
  IconButton,
  TextField,
  Grid,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Paper,
  Menu,
  MenuItem,
} from '@mui/material';
import { Add, Delete, Edit, MoreVert, Save, Cancel, Done, Undo } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack'; // For notifications

// Style for the strikethrough animation
const useStyles = makeStyles((theme) => ({
  itemText: {
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-block',
  },
  strikethrough: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      width: '100%',
      height: '2px',
      backgroundColor: 'gray',
      top: '50%',
      transform: 'translateY(-50%) translateX(-100%)',
      animation: '$strikethrough 0.5s forwards',
    },
  },
  '@keyframes strikethrough': {
    to: {
      transform: 'translateY(-50%) translateX(0)',
    },
  },
}));

function ListDetail() {
  const { id } = useParams();
  const [list, setList] = useState(null);
  const [items, setItems] = useState([]);
  const [newItemDesc, setNewItemDesc] = useState('');
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [emailsToAdd, setEmailsToAdd] = useState('');
  const [groupsToShare, setGroupsToShare] = useState([]);
  const [emailInput, setEmailInput] = useState('');
  const [groups, setGroups] = useState([]);
  const [editItemDialogOpen, setEditItemDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [editItemDesc, setEditItemDesc] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedShare, setSelectedShare] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [animatingItems, setAnimatingItems] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    fetchList();
    fetchGroups();
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Fetch items in useEffect or appropriate function
  const fetchItems = async () => {
    try {
      const response = await api.get(`/lists/${id}`);
      setItems(response.data.items);
    } catch (error) {
      console.error('Error fetching items:', error);
      enqueueSnackbar('Failed to fetch items.', { variant: 'error' });
    }
  };

  const fetchList = async () => {
    try {
      const response = await api.get(`/lists/${id}`);
      setList(response.data);
      setItems(response.data.items);
    } catch (error) {
      console.error('Error fetching list:', error);
      enqueueSnackbar('Failed to fetch list details.', { variant: 'error' });
    }
  };
  const fetchGroups = async () => {
    try {
      const response = await api.get('/groups');
      setGroups(response.data);
    } catch (error) {
      console.error('Error fetching groups:', error);
      enqueueSnackbar('Failed to fetch groups.', { variant: 'error' });
    }
  };

  const handleAddItem = async (e) => {
    e.preventDefault();
    if (newItemDesc.trim() === '') return;
    try {
      await api.post(`/lists/${id}/items`, { shortDescription: newItemDesc });
      setNewItemDesc('');
      fetchList();
      enqueueSnackbar('Item added successfully.', { variant: 'success' });
    } catch (error) {
      console.error('Error adding item:', error);
      enqueueSnackbar('Failed to add item.', { variant: 'error' });
    }
  };

  const handleDeleteItem = async (itemId) => {
    try {
      await api.delete(`/lists/${id}/items/${itemId}`);
      fetchList();
      enqueueSnackbar('Item deleted successfully.', { variant: 'success' });
    } catch (error) {
      console.error('Error deleting item:', error);
      enqueueSnackbar('Failed to delete item.', { variant: 'error' });
    }
  };

  const handleEditItem = (item) => {
    setCurrentItem(item);
    setEditItemDesc(item.shortDescription);
    setEditItemDialogOpen(true);
  };

  const handleUpdateItem = async () => {
    if (editItemDesc.trim() === '') return;
    try {
      await api.put(`/lists/${id}/items/${currentItem._id}`, { shortDescription: editItemDesc });
      setEditItemDialogOpen(false);
      setCurrentItem(null);
      setEditItemDesc('');
      fetchList();
      enqueueSnackbar('Item updated successfully.', { variant: 'success' });
    } catch (error) {
      console.error('Error updating item:', error);
      enqueueSnackbar('Failed to update item.', { variant: 'error' });
    }
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleShareList = async () => {
    const emailList = emailsToAdd
      .split(',')
      .map((email) => email.trim().toLowerCase())
      .filter((email) => email !== '');
  
    if (emailList.length === 0 && groupsToShare.length === 0) {
      enqueueSnackbar('Please add at least one email or group to share with.', { variant: 'warning' });
      return;
    }
  
    // Simple email format validation
    const invalidEmails = emailList.filter(
      (email) => !/\S+@\S+\.\S+/.test(email)
    );
    if (invalidEmails.length > 0) {
      enqueueSnackbar(`Invalid email(s): ${invalidEmails.join(', ')}`, {
        variant: 'warning',
      });
      return;
    }
  
    try {
      await api.post(`/lists/${id}/share`, {
        emails: emailList,
        groupIds: groupsToShare.map((group) => group._id),
      });
      setShareDialogOpen(false);
      setEmailsToAdd([]);
      setGroupsToShare([]);
      fetchList();
      enqueueSnackbar('List shared successfully.', { variant: 'success' });
    } catch (error) {
      console.error('Error sharing list:', error);
      enqueueSnackbar(error.response?.data?.message || 'Failed to share list.', { variant: 'error' });
    }
  };
  

  const handleUnshare = async (shareItem) => {
    try {
      if (shareItem.kind === 'User') {
        await api.post(`/lists/${id}/unshare`, { email: shareItem.item.email });
      } else if (shareItem.kind === 'Group') {
        await api.post(`/lists/${id}/unshare`, { groupId: shareItem.item._id });
      }
      fetchList();
      enqueueSnackbar('List unshared successfully.', { variant: 'success' });
    } catch (error) {
      console.error('Error unsharing list:', error);
      enqueueSnackbar(error.response?.data?.message || 'Failed to unshare list.', { variant: 'error' });
    }
  };

// Handle drag and drop
const handleOnDragEnd = async (result) => {
  if (!result.destination) return;

  const newItems = Array.from(items);
  const [reorderedItem] = newItems.splice(result.source.index, 1);
  newItems.splice(result.destination.index, 0, reorderedItem);

  setItems(newItems);

  // Save new order to backend
  try {
    await api.put(`/lists/${id}/items/reorder`, {
      itemIds: newItems.map((item) => item._id), // Use _id instead of id
    });
    enqueueSnackbar('Item order updated.', { variant: 'success' });
  } catch (error) {
    console.error('Error updating item order:', error);
    enqueueSnackbar('Failed to update item order.', { variant: 'error' });
  }
};

  // Handle menu open
  const handleMenuOpen = (event, shareItem) => {
    setAnchorEl(event.currentTarget);
    setSelectedShare(shareItem);
  };

  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedShare(null);
  };

  // Handle name edit toggle
  const handleNameDoubleClick = () => {
    setIsEditingName(true);
  };

  const handleNameChange = (e) => {
    setEditedName(e.target.value);
  };

  const handleNameSave = async () => {
    if (editedName.trim() === '') {
      enqueueSnackbar('Name cannot be empty.', { variant: 'warning' });
      return;
    }
    try {
      const response = await api.put(`/lists/${id}`, { name: editedName });
      setList(response.data);
      setIsEditingName(false);
      enqueueSnackbar('List name updated.', { variant: 'success' });
    } catch (error) {
      console.error('Error updating list name:', error);
      enqueueSnackbar('Failed to update list name.', { variant: 'error' });
    }
  };

  const handleNameCancel = () => {
    setEditedName(list.name);
    setIsEditingName(false);
  };

  // Handle complete action
  const handleCompleteItem = async (itemId) => {
    try {
      // Add the item to animatingItems
      setAnimatingItems((prev) => [...prev, itemId]);

      // Delay the completion to allow the animation to play
      setTimeout(async () => {
        await api.post(`/items/${itemId}/complete`);
        setItems((prevItems) =>
          prevItems.map((item) =>
            item._id === itemId ? { ...item, completedAt: new Date() } : item
          )
        );
        // Remove the item from animatingItems
        setAnimatingItems((prev) => prev.filter((id) => id !== itemId));
      }, 500); // Duration matches the CSS animation duration
    } catch (error) {
      console.error('Error completing item:', error);
      enqueueSnackbar('Failed to complete item.', { variant: 'error' });
      // Remove the item from animatingItems in case of error
      setAnimatingItems((prev) => prev.filter((id) => id !== itemId));
    }
  };

  // Handle uncomplete action
  const handleUncompleteItem = async (itemId) => {
    try {
      await api.post(`/items/${itemId}/uncomplete`);
      setItems((prevItems) =>
        prevItems.map((item) =>
          item._id === itemId ? { ...item, completedAt: null } : item
        )
      );
    } catch (error) {
      console.error('Error uncompleting item:', error);
      enqueueSnackbar('Failed to uncomplete item.', { variant: 'error' });
    }
  };

  // Split items into completed and uncompleted
  const uncompletedItems = items.filter((item) => !item.completedAt);
  const completedItems = items
    .filter((item) => item.completedAt)
    .sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt)); // Most recent first

  return (
    <Box p={1}>
      {list && (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {/* Editable List Name */}
            {isEditingName ? (
              <Box display="flex" alignItems="center">
                <TextField
                  value={editedName}
                  onChange={handleNameChange}
                  variant="outlined"
                  size="small"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleNameSave();
                    }
                  }}
                />
                <IconButton onClick={handleNameSave} color="primary">
                  <Save />
                </IconButton>
                <IconButton onClick={handleNameCancel} color="secondary">
                  <Cancel />
                </IconButton>
              </Box>
            ) : (
              <Typography
                variant="h5"
                gutterBottom
                onDoubleClick={handleNameDoubleClick}
                sx={{ cursor: 'pointer' }}
              >
                {list.name}
              </Typography>
            )}
            <Box display="flex" alignItems="center">
              <Button variant="outlined" sx={{ ml: 2, mb:1 }} onClick={() => setShareDialogOpen(true)}>
                Share List
              </Button>
            </Box>
          </Box>

          {/* Form to add new item */}
          <form onSubmit={handleAddItem}>
            <Grid container spacing={1} alignItems="flex-start">
              <Grid item xs={12} sm={8}>
                <TextField
                  label="New Item"
                  value={newItemDesc}
                  onChange={(e) => setNewItemDesc(e.target.value)}
                  multiline
                  minRows={1}
                  variant="outlined"
                  fullWidth
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleAddItem(e);
                    }
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<Add />}
                  fullWidth
                  sx={{ height: '100%' }}
                  size="small"
                >
                  Add Item
                </Button>
              </Grid>
            </Grid>
          </form>

          {/* Items Container */}
          <Box mt={2} style={{ maxHeight: '60vh', overflowY: 'auto' }}>
            {/* Uncompleted Items */}
            <Typography variant="subtitle1">Items</Typography>
            {uncompletedItems.length === 0 ? (
              <Typography>No items found.</Typography>
            ) : (
              uncompletedItems.map((item) => {
                const isAnimating = animatingItems.includes(item._id);

                return (
                  <Paper key={item._id} sx={{ p: 1, mb: 1 }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {/* Item Details with Conditional Strikethrough Animation */}
                      <Typography
                        variant="body2"
                        className={`${classes.itemText} ${
                          isAnimating ? classes.strikethrough : ''
                        }`}
                      >
                        {item.shortDescription}
                      </Typography>
                      {/* Action Icons */}
                      <Box>
                        <IconButton
                          onClick={() => handleCompleteItem(item._id)}
                          color="primary"
                          disabled={isAnimating} // Disable button during animation
                          size="small"
                        >
                          <Done fontSize="small" />
                        </IconButton>
                        <IconButton onClick={() => handleEditItem(item)} size="small">
                          <Edit fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Paper>
                );
              })
            )}

            {/* Completed Items */}
            <Typography variant="subtitle1">Completed Items</Typography>
            {completedItems.length === 0 ? (
              <Typography>No completed items.</Typography>
            ) : (
              completedItems.map((item) => (
                <Paper key={item._id} sx={{ p: 1, mb: 1 }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {/* Item Details with Static Strikethrough */}
                    <Typography
                      variant="body2"
                      sx={{ textDecoration: 'line-through' }}
                    >
                      {item.shortDescription}
                    </Typography>
                    {/* Action Icons */}
                    <Box>
                      <IconButton
                        onClick={() => handleUncompleteItem(item._id)}
                        color="primary"
                        size="small"
                      >
                        <Undo fontSize="small" />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteItem(item._id)}
                        color="error"
                        size="small"
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                </Paper>
              ))
            )}
          </Box>

          {/* Edit Item Dialog */}
          <Dialog
            open={editItemDialogOpen}
            onClose={() => setEditItemDialogOpen(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Edit Item</DialogTitle>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateItem();
              }}
            >
              <DialogContent>
                <TextField
                  label="Item Description"
                  value={editItemDesc}
                  onChange={(e) => setEditItemDesc(e.target.value)}
                  multiline
                  minRows={1}
                  variant="outlined"
                  fullWidth
                  autoFocus
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setEditItemDialogOpen(false)}>Cancel</Button>
                <Button type="submit" variant="contained">
                  Update
                </Button>
              </DialogActions>
            </form>
          </Dialog>

          {/* Dialog for sharing the list */}
          <Dialog
            open={shareDialogOpen}
            onClose={() => setShareDialogOpen(false)}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>Share List</DialogTitle>
            <DialogContent>
            <Typography variant="subtitle1">Shared With:</Typography>
              <Box mt={2} display="flex" flexWrap="wrap">
                {list.sharedWith && list.sharedWith.length > 0 ? (
                  list.sharedWith.map((shareItem, index) => (
                    <Chip
                      key={index}
                      label={(shareItem.item.name || shareItem.item.email)}
                      onDelete={() => handleUnshare(shareItem)}
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Not shared
                  </Typography>
                )}
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                  label="Emails to Add"
                  value={emailsToAdd}
                  onChange={(e) => setEmailsToAdd(e.target.value)}
                  variant="outlined"
                  fullWidth
                  placeholder="Enter emails separated by commas"
                  multiline
                  minRows={2}
                  helperText="Separate multiple emails with commas"
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                    options={groups}
                    getOptionLabel={(option) => option.name}
                    value={groupsToShare}
                    onChange={(event, inputValue) => {
                      setGroupsToShare(inputValue);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          {...getTagProps({ index })}
                          key={option._id}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Groups to Share With"
                        placeholder="Select groups"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShareDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleShareList} variant="contained">
                Share
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
}

export default ListDetail;
