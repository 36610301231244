// src/components/VerifyEmail.js

import React, {useEffect} from "react";
import { auth } from "../firebase";
import { sendEmailVerification, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

function VerifyEmail() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        // If email is verified, redirect to dashboard
        navigate("/dashboard");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const resendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      enqueueSnackbar("Verification email resent. Please check your inbox.", {
        variant: "info",
      });
    } catch (error) {
      console.error("Error resending verification email:", error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handleLogout = async () => {
    await auth.signOut();
    navigate("/");
  };

  return (
    <div>
      <h2>Please verify your email address</h2>
      <p>A verification email has been sent to your email address.</p>
      <button onClick={resendVerificationEmail}>
        Resend Verification Email
      </button>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default VerifyEmail;
