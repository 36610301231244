// src/components/PublicLayout.js

import React from 'react';
import { AppBar, Toolbar, Button, CssBaseline, Container } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import logo from '../assets/company-logo.svg'; // Import your SVG logo

function PublicLayout() {
  return (
    <div>
      <CssBaseline />
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <img src={logo} alt="Company Logo" style={{ height: '40px' }} />
          </Link>
          <div style={{ flexGrow: 1 }} />
          <Button color="inherit" component={Link} to="/learn-more">
            Learn More
          </Button>
          <Button color="inherit" component={Link} to="/register">
            Sign Up
          </Button>
          <Button color="inherit" component={Link} to="/login">
            Login
          </Button>
        </Toolbar>
      </AppBar>
      {/* Main content */}
      <main>
        <Outlet />
      </main>
    </div>
  );
}

export default PublicLayout;
