// client/src/components/Register.js

import React, { useState } from "react";
import { auth } from "../firebase";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import api from "../api"; // Import your configured Axios instance

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // State for error messages
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(""); // Reset error message
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // Send verification email
      await sendEmailVerification(userCredential.user);
      enqueueSnackbar('Verification email sent. Please check your inbox.', { variant: 'info' });
      // Redirect to a page informing the user to verify their email
      navigate('/verify-email');
      // Get the Firebase ID token
      const idToken = await userCredential.user.getIdToken();

      // Send a request to your backend to create the user in MongoDB
      await api.post(
        "/users/register", // Endpoint we'll create in the backend
        {},
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      navigate("/dashboard");
    } catch (error) {
      console.error("Registration error:", error);
      setError(error.message); // Set error message
    }
  };

  return (
    <form onSubmit={handleRegister}>
      <h2>Register</h2>
      {error && <p style={{ color: "red" }}>{error}</p>} {/* Display error */}
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <br />
      <input
        type="password"
        placeholder="Password (minimum 6 characters)"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        minLength={6}
      />
      <br />
      <button type="submit">Register</button>
    </form>
  );
}

export default Register;
