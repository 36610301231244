// src/components/HomePage.js

import React from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

// Import images or illustrations (ensure these files exist in your assets folder)
import heroImage from '../assets/hero-image.svg';
import feature1Image from '../assets/feature1.svg';
import feature2Image from '../assets/feature2.svg';

const useStyles = makeStyles((theme) => ({
  heroSection: {
    padding: theme.spacing(8, 0),
    backgroundColor: '#f5f5f5',
  },
  heroContent: {
    maxWidth: 1200,
    margin: '0 auto',
  },
  heroText: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(4),
    },
  },
  heroImage: {
    width: '100%',
    maxWidth: 500,
  },
  featureSection: {
    padding: theme.spacing(8, 0),
  },
  featureImage: {
    width: '100%',
    height: 'auto',
    maxHeight: 300, // Set a max height to standardize
    objectFit: 'contain',
  },
  featureCard: {
    textAlign: 'center',
    boxShadow: 'none',
  },
  ctaSection: {
    padding: theme.spacing(8, 0),
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

function HomePage() {
  const classes = useStyles();

  return (
    <div>
      {/* Hero Section */}
      <Box className={classes.heroSection}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Text Content */}
            <Grid item xs={12} md={6} className={classes.heroText}>
              <Typography variant="h2" component="h1" gutterBottom>
                Stay Organized and Collaborate Effortlessly
              </Typography>
              <Typography variant="h5" color="textSecondary" paragraph>
                Create shared to-do lists and group gift lists with ease. Collaborate with others and manage tasks efficiently.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/login"
                sx={{ mr: 2 }}
              >
                Get Started
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                component={Link}
                to="/learn-more"
                sx={{ mt: { xs: 2, md: 0 } }}
              >
                Learn More
              </Button>
            </Grid>
            {/* Hero Image */}
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center">
                <img src={heroImage} alt="Collaboration" className={classes.heroImage} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Box className={classes.featureSection}>
        <Container maxWidth="lg">
          <Typography variant="h4" align="center" gutterBottom>
            Key Features
          </Typography>
          <Grid container spacing={4} alignItems="flex-start">
            {/* Feature 1 */}
            <Grid item xs={12} md={6}>
              <Box className={classes.featureCard}>
                <img
                  src={feature1Image}
                  alt="Shared To-Do Lists"
                  className={classes.featureImage}
                />
                <Typography variant="h5" gutterBottom>
                  Shared To-Do Lists
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Create and share to-do lists with anyone. Keep track of tasks and collaborate in real-time.
                </Typography>
              </Box>
            </Grid>
            {/* Feature 2 */}
            <Grid item xs={12} md={6}>
              <Box className={classes.featureCard}>
                <img
                  src={feature2Image}
                  alt="Group Gift Lists"
                  className={classes.featureImage}
                />
                <Typography variant="h5" gutterBottom>
                  Anonymous Group Gift Lists
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Organize group gifts where participants can purchase items anonymously. Perfect for surprises!
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Call-to-Action Section */}
      <Box className={classes.ctaSection}>
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom>
            Join ListLink Today
          </Typography>
          <Typography variant="h6" gutterBottom>
            Start collaborating and managing your tasks effortlessly.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component={Link}
            to="/login"
          >
            Sign Up Now
          </Button>
        </Container>
      </Box>
    </div>
  );
}

export default HomePage;
